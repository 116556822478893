module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Balanço 2020 | Doutores da Alegria","short_name":"Balanço 2020","start_url":"/","icons":[{"src":"./static/favicon-16x16","sizes":"16x16","type":"image/png"},{"src":"./static/favicon-32x32","sizes":"32x32","type":"image/png"},{"src":"./static/favicon-144x144","sizes":"144x144","type":"image/png"},{"src":"./static/favicon-180x180","sizes":"180x180","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
